<template>
  <div>
    <load-spinner v-if="isLoading"></load-spinner>
    <base-header :base-title="headTitle"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitleName"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                :to="{ name: 'payment-global' }"
              >
                <i class="flaticon2-back"></i>
                {{ $t("button.previous") }}
              </router-link>
              <button
                class="btn btn-default btn-bold btn-upper btn-font-sm"
                @click="save()"
              >
                <i class="fa fa-save"></i>
                {{ btnData }}
              </button>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <!-- Form -->
          <!-- {{ paymentDetail }} -->
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3">Name</label>
            <div class="col-lg-8">
              <input type="text" class="form-control" v-model="payment.name" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3">Icon</label>
            <div class="col-lg-6">
              <div
                class="dropzone"
                action="inc/api/dropzone/upload.php"
                id="kt-dropzone-one"
              >
                <div class="dropzone-msg dz-message needsclick">
                  <h1><i class="fa fa-upload" aria-hidden="true"></i></h1>
                  <h3 class="dropzone-msg-title">Upload</h3>
                  <span class="dropzone-msg-desc"
                    >Tarik gambar ke sini atau klik untuk upload.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Deskripsi</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                class="form-control"
                v-model="payment.description"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Payment Type</label
            >
            <div class="col-lg-8 pt-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="hasChildPayment"
                  :disabled="isHasChild"
                  v-model="parentChannel"
                  value="false"
                />
                <label class="form-check-label" for="hasChildPayment1"
                  >Channel</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="hasChildPayment"
                  id="hasChildPayment2"
                  :disabled="isHasChild"
                  v-model="parentChannel"
                  value="true"
                />
                <label class="form-check-label" for="hasChildPayment2"
                  >Sub Channel</label
                >
              </div>
              <template v-if="parentChannel == 'true'">
                <select
                  v-model="payment.parentId"
                  name="parent"
                  class="form-control"
                >
                  <option
                    v-for="(payment, index) in notMe(paymentMethod)"
                    :key="index"
                    :value="payment.id"
                  >
                    {{ payment.name }}
                  </option>
                </select>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Payment Type</label
            >
            <div class="col-lg-8 pt-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="typePayment"
                  id="typePayment1"
                  value="true"
                  v-model="payment.instruction.mobile"
                />
                <label class="form-check-label" for="typePayment1"
                  >Only Mobile</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="typePayment"
                  id="typePayment2"
                  value="false"
                  v-model="payment.instruction.mobile"
                />
                <label class="form-check-label" for="typePayment2">All</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Show Payment</label
            >
            <div class="col-lg-8 pt-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="enablePayment"
                  id="enablePayment1"
                  v-model="payment.enabled"
                  value="true"
                />
                <label class="form-check-label" for="enablePayment1"
                  >Show</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="enablePayment"
                  id="enablePayment2"
                  v-model="payment.enabled"
                  value="false"
                />
                <label class="form-check-label" for="enablePayment2"
                  >Hide</label
                >
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3">
              Expired Payment
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="payment.expiry"
                  />
                </div>
                <div class="col-4">
                  <select
                    name="expiryUnit"
                    v-model="payment.expiryUnit"
                    class="form-control"
                  >
                    <option value="MINUTE">MINUTE</option>
                    <option value="HOUR">HOUR</option>
                    <option value="DAY">DAY</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Overview</label
            >
            <div class="col-lg-8">
              <div
                class="input-group mb-2"
                v-for="(overview, indexOverview) in payment.instruction
                  .overview"
                :key="indexOverview"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="payment.instruction.overview[indexOverview]"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="addOverview"
                    v-if="
                      payment.instruction.overview.length - 1 == indexOverview
                    "
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-primary"
                    @click="deleteOverview(indexOverview)"
                    v-else
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Payload</label
            >
            <div class="col-lg-8">
                <button class="btn btn-sm btn-primary" @click="addPayload"> Tambah</button>
                <div class="row mb-2 align-items-center" v-for="(payload, indexPayload) in payment.instruction.payload" :key="indexPayload">
                     <div class="col-4 form-group">
                    <label for="">Type</label>
                     <input
                        type="text"
                        class="form-control"
                        v-model="payment.instruction.payload[indexPayload].type"
                    />
                     </div>
                     <div class="col-6 form-group">
                         <label for="">name </label>
                     <input
                        type="text"
                        class="form-control"
                        v-model="payment.instruction.payload[indexPayload].name"
                    />
                     </div>
                     <div class="col-2">
                         <button class="btn btn-primary btn-sm" @click="deletePayload(indexPayload)">-</button>
                     </div>
                     
                </div>
             
              
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Instruction</label
            >
            <div class="col-lg-8">
              <button
                class="btn btn-outline-info mb-3"
                @click="addInstructionPayment"
              >
                Tambah
              </button>
              <template
                v-if="
                  payment.instruction &&
                  payment.instruction.detail &&
                  payment.instruction.detail.length > 0
                "
              >
                <div
                  class="border border-dark rounded p-4 d-relative mb-3"
                  v-for="(instruction2, index) in payment.instruction.detail"
                  :key="index"
                >
                  <span
                    class="position-absolute"
                    style="right: 5px; top: 5px"
                    @click="deleteInstructionPayment(index)"
                    ><i class="fa fa-times pointer"></i
                  ></span>
                  <div class="form-group">
                    <label for="">Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="payment.instruction.detail[index].title"
                    />
                  </div>
                  <hr />
                  <div class="form-group">
                    <label for="">Instruction </label>
                    <div
                      :name="`instruction${index}`"
                      :id="`summernote-${index}`"
                      class="summer-note"
                    ></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >After Charge</label
            >
            <div class="col-lg-8">
              <div class="form-group">
                <div
                  :name="`afterInstruction`"
                  :id="`afterInstruction`"
                  class="summer-note"
                ></div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label text-right pr-3"
              >Payload</label
            >
            <div class="col-lg-8">
              <textarea
                rows="4"
                class="form-control"
                v-model="payment.payload"
              />
            </div>
          </div>
        </div>
        <!-- <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import { mapState, mapActions } from "vuex";
import Payment from "./../../model/payment-model";
import Instruction from "../../model/instruction-model";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import LoadSpinner from "./../../../components/_general/LoadSpinner";

const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    BaseTitle,
    BaseHeader,
    LoadSpinner
  },
  data() {
    return {
      headTitle: "",
      btnData: "",
      headTitleName: "",
      payment: new Payment(),
      parentChannel: "false",
      isHasChild: false,
    };
  },
  computed: {
    ...mapState("PaymentStore", {
      paymentDetail: (state) => state.paymentDetail,
      paymentMethod: (state) => state.paymentMethod,
      isLoading: state => state.loadingAPI
    }),
  },
  async mounted() {
    if (this.$route.params.id) {
      this.headTitle = "Edit Payment";
      this.btnData = "Simpan";
      await this.getPaymentMethodsId(this.$route.params.id);
      this.headTitleName = this.paymentDetail.name;
      this.payment = this.paymentDetail;
      this.isHasChild = this.paymentDetail.hasChild;
      this.parentChannel = this.paymentDetail.parentId ? "true" : "false";
      if (this.payment.instruction && this.payment.instruction.detail) {
        for (
          let index = 0;
          index < this.payment.instruction.detail.length;
          index++
        ) {
          setTimeout(() => {
            this.summerNote(`#summernote-${index}`, `${index}`);
          }, 500);
        }
      }
    } else {
      this.headTitle = "Add Payment";
      this.btnData = "Simpan";
      this.headTitleName = "Add Payment";
    }
    this.getallPaymentMethods();
    const vx = this;
    var myDropzone = new Dropzone("#kt-dropzone-one", {
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      autoProcessQueue: false,
      createImageThumbnails: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      },
      init: function () {
        this.on("thumbnail", function (file) {
          var replacedDataURL = file.dataURL.substring(
            file.dataURL.indexOf(",") + 1
          );
          vx.payment.icon = replacedDataURL;
          vx.payment.imageContentType = file.type;
        });
        this.on("removedfile", function (file) {
          vx.payment.image = null;
          vx.payment.imageContentType = null;
        });
      },
    });

    // if (this.modalData) {
    //   this.updateData(this.modalData);

    if (this.payment.icon) {
      var mockFile = { name: "mock.jpg", size: 12345 };
      myDropzone.options.addedfile.call(myDropzone, mockFile);
      myDropzone.options.thumbnail.call(
        myDropzone,
        mockFile,
        // "data:" + this.payment.imageContentType + ";base64," + this.payment.icon
        "data:image/svg+xml;base64," + this.payment.icon
      );
    }
    setTimeout(() => {
      this.summerAfterChange(`#afterInstruction`, "afterInstruction");
    }, 500);
  },
  methods: {
    ...mapActions({
      getPaymentMethodsId: "PaymentStore/GET_PAYMENT_METHODS_BY_ID",
      getallPaymentMethods: "PaymentStore/GET_ALL_PAYMENT_METHODS",
      postPaymentMethods: "PaymentStore/POST_PAYMENT_METHODS",
    }),
    addInstructionPayment() {
      this.payment.instruction.detail.push(new Instruction());
      //   console.log(`#summernote-${this.payment.instruction.detail.length-1}`)
      setTimeout(() => {
        this.summerNote(
          `#summernote-${this.payment.instruction.detail.length - 1}`,
          `${this.payment.instruction.detail.length - 1}`
        );
      }, 500);
    },
    deleteInstructionPayment(indexDetail) {
      this.payment.instruction.detail.splice(indexDetail, 1);
    },
    addPayload() {
      const data = {
        type: "",
        name: "",
      };
      this.payment.instruction.payload.push(data);
    },
    deletePayload(index) {
      this.payment.instruction.payload.splice(index, 1);
    },
    addOverview(index) {
      this.payment.instruction.overview.push("");
    },
    deleteOverview(index) {
      this.payment.instruction.overview.splice(index, 1);
    },
    save() {
      if (!this.payment.name || !this.payment.description) {
        toastr.error("Silahkan isi data dengan benar");
        return false;
      }
      let message;
      if (this.payment.id) {
        message = "Perubahan data  akan tersimpan";
      } else {
        message = "Metode pembayaran akan tersimpan.";
      }
      if (this.parentChannel == "false") {
        this.payment.parentId = null;
      }
      const that = this;
      new SweetAlert().showConfirm({
        swalTitle: "Apakah Anda yakin?",
        swalText: message,
        swalType: "info",
        onConfirmButton: function () {
          that.postPaymentMethods(that.payment);
          // console.log(that.payment)
        },
        onCancelButton: function () {
          // blockUI.unblockModal();
        },
      });
    },
    summerNote(idEl, indexDetail, contentCode) {
      let config = {
        height: 150,
        callbacks: {
          onChange: (contents) => {
            this.payment.instruction.detail[indexDetail].instruction = contents;
          },
          onBlurCodeview: (contents, $editable) => {
            this.payment.instruction.detail[indexDetail].instruction = contents;
          },
        },
      };
      $(idEl).summernote(config);
      $(idEl).summernote("code", contentCode);
      if ("string" == typeof contentCode) {
        $(idEl).summernote("code", contentCode);
      } else if (
        !_.isUndefined(this.payment.instruction.detail[indexDetail].instruction)
      ) {
        $(idEl).summernote(
          "code",
          this.payment.instruction.detail[indexDetail].instruction
        );
      }
    },
    summerAfterChange(idEl, keyName, contentCode) {
      let config = {
        height: 150,
        callbacks: {
          onChange: (contents) => {
            this.payment[keyName] = contents;
          },
          onBlurCodeview: (contents, $editable) => {
            this.payment[keyName] = contents;
          },
        },
      };
      $(idEl).summernote(config);
      if ("string" == typeof contentCode) {
        $(idEl).summernote("code", contentCode);
      } else if (!_.isUndefined(this.payment[keyName])) {
        $(idEl).summernote("code", this.payment[keyName]);
      }
    },
    notMe(data) {
      const route = this.$route.params.id;
      const stringData = JSON.stringify(data);
      const dataParse = JSON.parse(stringData);
      return dataParse.filter((item) => item.id != route);
    },
  },
};
</script>

<style>
</style>